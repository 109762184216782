import React from "react";
import HomePage from "../components/layout/Home";
import PrivacyModal from "../components/home/privacy-modal";

export default props => {
  return (
    <HomePage>
      <PrivacyModal />
    </HomePage>
  );
};
