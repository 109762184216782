import React from "react";
import { navigate, Link } from "gatsby";
import "../../../static/styles/_login-modal.scss";
import TakeOver from "../common/Takeover";
import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";

let Close = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  filter: invert(1);
  svg {
    fill: #fff;
  }
`;
export default ({ title, delay, children }) => {
  return (
    <>
      <TakeOver
        extraClass={delay ? "no-delay" : ""}
        onClick={e => {
          e.preventDefault();
          navigate("/");
        }}
      >
        <div
          className="modal"
          onClick={e => {
            e.stopPropagation();
            navigate("/");
          }}
        >
          <div className="left-half darkbluebg">
            <h2>{title}</h2>
          </div>
          <div className=" ">
            <Close className="closeX addhveffect" to="/">
              <CancelIcon />
            </Close>
            <pre className="right-half darkbluebg">{children}</pre>
          </div>
        </div>
      </TakeOver>
    </>
  );
};
